import { mapGetters } from 'vuex'
import { formatDate, isIOS } from '@/utils'
import dayjs from 'dayjs'
export default {
  name: 'content-card',
  data: () => ({
    chevronUp: 'transform: rotate(270deg); transition: transform 0.2s ease;',
    chevronDown: 'transform: rotate(90deg); transition: transform 0.2s ease;',
    renderComponent: true,
    content: []
  }),
  props: {
    updatedContent: {
      type: Array,
      default: () => {}
    },
    updatedData: {
      type: Object,
      default: () => {}
    },
    doAttendance: {
      type: Function,
      default: () => {}
    },
    showDetail: {
      type: Function,
      default: () => {}
    },
    generateLink: {
      type: Function,
      default: () => {}
    },
    showGradingModal: {
      type: Function,
      default: () => {}
    },
    showVideoDetail: {
      type: Function,
      default: () => {}
    },
    activeCard: {
      type: String,
      default: 'NEXT'
    }
  },
  components: {
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron'),
    Document: () => import(/* webpackChunkName: "document" */ '@/components/icons/Document'),
    TimeOutline: () => import(/* webpackChunkName: "time-ouline" */ '@/components/icons/TimeOutline'),
    ChatBubble: () => import(/* webpackChunkName: "chat-bubble" */ '@/components/icons/ChatBubble'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Subtract: () => import(/* webpackChunkName: "subtract" */ '@/components/icons/Subtract'),
    VideoPlayer: () => import(/* webpackChunkName: "VideoPlayer" */ '@/components/video/VideoPlayer.vue')
  },
  watch: {
    updatedData(value) {
      this.updatedContent[value.indexSelected].messageList.push(value)
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile', 'loading'])
  },

  methods: {
    getDuration(totalSeconds) {
      let hours = Math.floor(totalSeconds / 3600)
      totalSeconds %= 3600
      let minutes = Math.floor(totalSeconds / 60)
      let seconds = totalSeconds % 60
      minutes = String(minutes).padStart(2, '0')
      hours = String(hours).padStart(2, '0')
      seconds = String(seconds).padStart(2, '0')
      return hours + ':' + minutes + ':' + seconds
    },
    compareTime(endTime) {
      const today = dayjs().format('DD MMMM YYYY HH:mm')
      const endTimeAdd7Days = dayjs(endTime).add(5, 'days').format('DD MMMM YYYY HH:mm')
      const isRecordingDisable = dayjs(today).isAfter(endTimeAdd7Days)
      return { isRecordingDisable, endTimeAdd7Days: `${dayjs(endTimeAdd7Days).format('DD MMMM YYYY')} jam ${dayjs(endTimeAdd7Days).format('HH:mm')}` }
    },
    checkStatusAttendance(status) {
      if (status === 'need_attendance') {
        return {
          text: 'Absen Sekarang',
          style: 'bg-yellow-secondary border-yellow-secondary text-yellow hover:text-yellow-300'
        }
      }
      if (status === 'done_attendance') {
        return {
          text: 'Sudah Absen',
          style: 'bg-grey-field border-grey-field text-white hover:text-grey-light'
        }
      }

      if (status === 'permission_attendance') {
        return {
          text: 'Izin Absen',
          style: 'bg-grey-field border-grey-field text-white hover:text-grey-light'
        }
      }

      if (status === 'not_attendance') {
        return {
          text: 'Tidak Absen',
          style: 'bg-white border-red text-red'
        }
      }

      if (status === 'permission_attendance') {
        return {
          text: 'Izin Absen',
          style: 'bg-grey-field border-grey-field text-white hover:text-grey-light'
        }
      }

      return {
        text: null,
        style: null
      }
    },
    getVideoOptions(url) {
      return {
        autoplay: false,
        controls: true,
        liveui: false,
        aspectRatio: '16:9',
        controlBar: {
          liveDisplay: true,
          pictureInPictureToggle: false,
          fullscreenToggle: !isIOS
        },
        userActions: {
          doubleClick: !isIOS
        },
        poster: require('@/assets/images/prakerja/cover.jpg'),
        sources: [
          {
            src: url,
            type: 'video/mp4'
          }
        ]
      }
    },
    formatDate(startDate, endDate, createdDate) {
      if (createdDate) return dayjs(createdDate).format('DD MMMM YYYY HH:mm')

      if (startDate === null) return ''

      return `${dayjs(startDate).format('DD MMMM YYYY HH:mm')}-${dayjs(endDate).format('HH:mm')}`
    }
  }
}
